<template>
    <!-- 
        Сторінка "Продажі"
        - дозволяє переглянути статистику по продажам, та з фільтрацією по співробітнику та періоду
    -->

    <!-- Компонент хедеру розділу -->
    <PageHeader :title="title" />

    <!-- 
        Компонент таблиці
        - :dataParams - параметри до кнопки фільтру
        - :objParams - параметри для фільтрації таблиці 
        - @search - подія, яка спрацьовує при пошуку у таблиці
        - :columns - конфігурація стовпців таблиці
        - :rows - масив з даними рядків таблиці
        - :pages - об'єкт із даними про пагінацію таблиці
        - :showpag - параметр, який вказує, чи відображати пагінацію на таблиці
        - @getdata - подія, яка спрацьовує при отриманні даних для таблиці
        - @changelimit - подія, яка спрацьовує при зміні ліміту записів на сторінці
        - @changeFilter - подія, яка спрацьовує при зміні фільтрів таблиці
        - @open - подія, яка спрацьовує при відкритті картки співробітника
    -->
    <tablecustom 
        :dataParams="dataParams"
        :objParams="objParams"
        @search="searchB"  
        :pages="objPages"
        :showpag="true"
        :columns="columns" 
        :rows="rows"
        @open="open"
        @changeFilter="changeFilter" 
        @changelimit="changelimit"
    />

</template>

<script>
import PageHeader from "@/components/page-header"; // хедер розділу
import tablecustom from '@/components/globaltable/index'; // компонент таблиці
import { storeS } from "@/store"; // глобальне сховище даних
import { Statistics } from '@/API' // клас для взаємодії з API 

let apiServe = new Statistics();

export default{
    components: {
        PageHeader,
        tablecustom,
    },
    data(){
        return{
            title: this.$t('Sales'), // Заголовок
            objPages: {}, // Об'єкт з даними про сторінки для розподілу даних по сторінках
            dataParams: { // Параметри для фільтрації таблиці
                status: true, // true - показати кнопку фільтр, false - сховати
                page: "sales"
            },
            objParams: { // Параметри для фільтрації даних
                page: '1',
                pagelimit: '10',
                search: '',
                workerId: '',
                pr1: '',
                pr2: ''
            },
            rows: [], // Дані для відображення у таблиці
            columns: [
                // Конфігурація колонок таблиці з інформацією про кожну колонку
                // Кожен елемент містить:
                // * name - назву колонки для локалізованого відображення,
                // * text - Ключове слово для отримання значення з даних
                // * align - Вирівнювання
                // * status - Статус відображення колонки
                // * mutate - Функція для мутації значення колонки (переклад)
                // * mutateClass - Функція для мутації класу колонки (стилі)
                {
                    name: this.$t('Employee'),
                    text: "workerName",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t('Quantity'),
                    text: "salesCount",
                    align: "left",
                    status: true,
                },
                {
                    name: this.$t("Sum"),
                    text: "salesSum",
                    align: "left",
                    status: true,
                }
            ],
        }
    },
    created(){
        this.getdata();
    },
    methods:{
        getdata(page){
            /*
                Функція для отримання даних для таблиці.
                Викликає API для отримання списку з параметрами з об'єкта objParams.
                Результат присвоюється властивостям rows та objPages (інформація про сторінки).
            */

            this.objParams.page = page != undefined ? page : '1';
            apiServe.statsBySales(this.objParams).then(result => {
                if(result.status == 'done') {
                    this.rows = result.data.items;
                    this.objPages = result.data;
                } else {
                    this.$toast.error(this.$t('error'));
                }
            })
        },
        changelimit: function(limit){
            /*
                Функція для зміни ліміту сторінки та виклику функції отримання даних getdata з новим лімітом
            */

            this.objParams.pagelimit = limit;
            this.getdata();
        },
        searchB(e){
            /*
                Функція пошуку по таблиці
            */

            this.objParams.search = e
            this.getdata();
        },
        changeFilter(name, value){
            /*
                Функція для зміни значення фільтрації та виклику функції отримання даних getdata з новими параметрами фільтрації
            */
           
            if(!name.type && value != undefined){
                if(name == 'pr'){
                    this.objParams['pr1'] = value[0];
                    this.objParams['pr2'] = value[1];
                } else {
                    this.objParams[name] = value;
                }
                this.getdata();
            }
        },
    }
}
</script>